<template>
    <div class="edit-profile-detail">
        <div class="title">
            <div v-html="$translate(title.toUpperCase())" />
            <span v-if="type === 'onlymanager'">(선택)</span>
        </div>
        <div class="section">
            <TextareaWithX v-model="userContent" :placeholder="placeholder" :isInputMode="inputMode" />
            <span v-if="type === 'car_model'" class="car-guide">*차량 브랜드-차종 형태로 입력해주세요</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Intro',
    props: {
        type: String,
        section: String,
        stage: {
            type: Number,
        },
        inputMode: {
            type: Boolean,
            default: false,
        },
        job_category: {
            type: Object,
        },
        jobType: {
            type: String,
        },
    },
    computed: {
        profile() {
            return this.$store.getters.profile || []
        },
        placeholder() {
            if (this.type === 'job') {
                if (this.jobType === 'A') {
                    return '회계사/IT기획자/치과의사 등'
                } else {
                    return '하시는 업무에 대해서 적어주세요.'
                }
            }
            if (this.type === 'family_intro')
                return '- 가족의 분위기, 성장 환경, 문화\n- 부모님의 학력, 직업\n- 결혼시 지원 가능한 부분, 노후대비, 상속 예정 내용 등을 입력해주세요.'
            if (this.type === 'finance')
                return '구체적으로 어떤 자산을 얼마나 갖고 있는지 적어주세요. 상속 예정인 내용을 적어주셔도 좋아요. (예금 등 현금성 자산, 주식, 부동산 등)'
            if (this.type === 'intro') return '성격, 직업, 본인만의 어필포인트 등을 알려주세요.'
            if (this.type === 'onlymanager')
                return '본인 입으로 말하기 민망하지만, 이성에게 어필이 될만한 포인트를 모두 적어주세요. 매니저가 경우에 따라 센스있게 회원님을 어필하는데 사용할 수 있어요. (본인 혹은 집안의 경제력, 성격적/신체적 매력 등)'
            if (this.type === 'car_model') return '(ex. 벤츠-s350)'
            return '입력해주세요'
        },
        title() {
            if (this.jobType === 'C') return 'company_task'
            return this.type
        },
        userContent: {
            get() {
                return this.content
            },
            set(val) {
                this.content = val
                // if (val) {
                const bool = this.type === 'onlymanager' ? false : Boolean(!val)
                this.$emit('disabled', bool)
                // }
            },
        },
    },
    watch: {
        type() {
            this.init()
        },
        stage() {
            this.init()
        },
    },

    data: () => ({
        content: '',
    }),
    methods: {
        onSave(key) {
            if (this.type === 'job') {
                if (this.jobType === 'A') {
                    this.$set(this.profile, 'company', [
                        { ...this.profile.company[0], job_name: this.content, employment_status: 1 },
                    ])
                } else {
                    this.$set(this.profile, 'company', [
                        { ...this.profile.company[0], company_task: this.content, employment_status: 1 },
                    ])
                }
            }
            this.$set(this.profile, key, this.content)
        },
        init() {
            if (this.type === 'job') {
                if (this.jobType === 'A') {
                    this.userContent = this.profile.company[0].job_name
                } else {
                    this.userContent = this.profile.company[0].company_task
                }
            } else {
                this.userContent = this.profile[this.type] ? this.profile[this.type] : ''
            }
        },
    },
    mounted() {
        this.init()
    },
}
</script>

<style scoped lang="scss">
::v-deep .textarea-with-x {
    textarea {
        height: 250px;
        line-height: 24px;
    }
}
.title {
    display: flex;
    justify-content: center;
    span {
        font-size: 14px;
        line-height: 24px;
        align-self: flex-end;
        font-weight: normal;
    }
}
.car-guide {
    font-size: 12px;
    margin-top: 12px;
    display: block;
    color: rgb(100, 100, 104);
}
</style>
